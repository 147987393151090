import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const SolutionNewHighlightSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const LynePageSectionListContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media (max-width: 600px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const Title = styled.h2`
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const Subtitle = styled.p`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  margin-bottom: 25px;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ order }) => (order === 1 ? "0px 30px" : "0px")};

  @media (max-width: 1024px) {
    margin: ${({ order }) => (order === 1 ? "0px 20px" : "0px")};
  }

  @media (max-width: 600px) {
    margin: ${({ order }) => (order === 1 ? "20px 0px" : "0px")};
  }
`

const Imagebox = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    width: calc(100% + 40px);
    margin: 0px 0px 15px -20px;
  }
`

const IframeBox = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  iframe,
  video {
    width: 100%;
    height: 100%;
    margin: 0px;
  }
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const Text = styled.div`
  font-size: 14px;
  line-height: 1.1;
  font-family: "Gotham Book";
`

const SolutionNewHighlightSection = ({ intl, data, id }) => {
  return (
    data && (
      <SolutionNewHighlightSectionWrapper
        id={id}
        className={id ? "lyneup-page-section" : ""}
      >
        <LynePageSectionListContainer>
          {data.title && (
            <Title
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
          )}

          {data.subtitle && (
            <Subtitle
              dangerouslySetInnerHTML={{
                __html: data.subtitle,
              }}
            />
          )}
          {data.list && (
            <Row>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <Column key={index.toString()} order={index}>
                      {item.image && (
                        <Imagebox>
                          {index == 0 ? (
                            <IframeBox>
                              <ReactPlayer
                                url="https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/Solutions_textiles_-_Points_forts_1.mp4"
                                playing={true}
                                controls={false}
                                muted={true}
                                loop={true}
                                width="100%"
                                height="100%"
                                playsinline={true}
                                playsInline
                              />
                            </IframeBox>
                          ) : index == 1 ? (
                            <IframeBox>
                              <ReactPlayer
                                url="https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/Solutions_textiles_-_Points_forts_2.mp4"
                                playing={true}
                                controls={false}
                                muted={true}
                                loop={true}
                                width="100%"
                                height="100%"
                                playsinline={true}
                                playsInline
                              />
                            </IframeBox>
                          ) : (
                            <Image
                              className="lazyload"
                              data-src={item.image}
                              alt={item.image_alt ? item.image_alt : ""}
                            />
                          )}
                        </Imagebox>
                      )}
                      {item.text && (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      )}
                    </Column>
                  )
                )
              })}
            </Row>
          )}
        </LynePageSectionListContainer>
      </SolutionNewHighlightSectionWrapper>
    )
  )
}

export default injectIntl(SolutionNewHighlightSection)
