import React, { useState, useRef } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import "flickity/css/flickity.css"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null
import SolutionVideoSliderPopup from "../SolutionVideoSliderPopup"

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: false,
  lazyLoad: true,
  cellAlign: "left",
}

const SolutionTalkingSectionWrapper = styled.div`
  width: 100%;
  display: ${({ mobile }) => (mobile ? "none" : "flex")};
  align-items: center;
  background: #dff1f2;

  @media (max-width: 600px) {
    display: ${({ mobile }) => (mobile ? "flex" : "none")};
    width: calc(100% + 20px);
    margin-left: -10px;
  }
`

const LynePageSectionListContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media (max-width: 600px) {
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 40px;
  }
`

const Title = styled.h2`
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ order }) => (order === 1 ? "0px 30px" : "0px")};
  position: relative;

  @media (max-width: 1024px) {
    margin: ${({ order }) => (order === 1 ? "0px 20px" : "0px")};
  }

  @media (max-width: 600px) {
    margin: ${({ order }) => (order === 1 ? "20px 0px" : "0px")};
  }
`

const Imagebox = styled.div`
  width: 100%;
  display: flex;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const VideoPlayIcon = styled.img`
  width: 100%;
  max-width: 40px;
  object-fit: contain;
  margin: 0px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Text = styled.div`
  max-width: calc(100% - 30px);
  background: #fff;
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 12px;
  line-height: 1.2;
  color: #262626;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: 30px;
  left: auto;
  right: auto;
  text-align: center;
`

const MobileBox = styled.div`
  width: 100%;
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;

  .flickity-prev-next-button {
    background-color: transparent;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    z-index: 3;

    @media (max-width: 600px) {
      width: 15px;
      height: 15px;
      border: 0px solid #111;
    }

    :hover {
      background-color: #111;
      color: #fff;

      @media (max-width: 600px) {
        background-color: transparent;
        color: #111;
      }
    }

    > .flickity-button-icon {
      @media (max-width: 600px) {
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .flickity-prev-next-button.next {
    @media (max-width: 600px) {
      right: -15px;
    }
  }
  .flickity-prev-next-button.previous {
    @media (max-width: 600px) {
      left: -15px;
    }
  }
`

const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;

  @media (max-width: 850px) {
    max-width: 70%;
    margin: 0px 10px 0px 0px;
  }
`

const SolutionTalkingSection = ({ intl, data, mobile }) => {
  var prevNext = useRef()
  const [modalOpen, setmodalOpen] = useState(false)
  const [videoOpen, setvideoOpen] = useState(null)

  const handleVideo = index => {
    setmodalOpen(!modalOpen)
    setvideoOpen(index)
  }
  return (
    data && (
      <SolutionTalkingSectionWrapper mobile={mobile}>
        {data.list && (
          <SolutionVideoSliderPopup
            data={data.list}
            open={modalOpen}
            video={videoOpen}
            closeHandle={() => setmodalOpen(false)}
          />
        )}
        <LynePageSectionListContainer>
          {data.title && (
            <Title
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
          )}
          {data.list && (
            <Row>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <Column key={index.toString()} order={index}>
                      {item.image && (
                        <Imagebox onClick={() => handleVideo(index)}>
                          <Image
                            className="lazyload"
                            data-src={item.image}
                            alt={item.image_alt ? item.image_alt : ""}
                          />
                          <VideoPlayIcon
                            className="lazyload"
                            data-src="https://static.percko.com/uploads/19d469eb-6651-40e3-ae5c-38ded39d720b.svg"
                          />
                          {item.text && (
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: item.text,
                              }}
                            />
                          )}
                        </Imagebox>
                      )}
                    </Column>
                  )
                )
              })}
            </Row>
          )}

          {typeof window !== "undefined" && data.list && (
            <MobileBox>
              <Carousel>
                <div style={{ width: "100%" }}>
                  <Flickity
                    flickityRef={c => (prevNext = c)}
                    options={flickityOptions}
                    className={"carousel"}
                    reloadOnUpdate
                    lazyLoad={true}
                  >
                    {data.list.map((item, index) => {
                      return (
                        item && (
                          <Card key={index.toString()} className="card">
                            {item.image && (
                              <Imagebox onClick={() => handleVideo(index)}>
                                <Image
                                  className="lazyload"
                                  data-src={item.image}
                                  alt={item.image_alt ? item.image_alt : ""}
                                />
                                <VideoPlayIcon
                                  className="lazyload"
                                  data-src="https://static.percko.com/uploads/19d469eb-6651-40e3-ae5c-38ded39d720b.svg"
                                />
                                {item.text && (
                                  <Text
                                    dangerouslySetInnerHTML={{
                                      __html: item.text,
                                    }}
                                  />
                                )}
                              </Imagebox>
                            )}
                          </Card>
                        )
                      )
                    })}
                  </Flickity>
                </div>
              </Carousel>
            </MobileBox>
          )}
        </LynePageSectionListContainer>
      </SolutionTalkingSectionWrapper>
    )
  )
}

export default injectIntl(SolutionTalkingSection)
